// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/index';
import '/vendor/js/flipclock.js';

$(document).ready(function () {
  $('.clock').FlipClock(600, {
    countdown: true,
  });

  offer.getState(function (data) {});
});
